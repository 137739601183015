<template>
  <div>
    <va-card class="mb-4">
      <va-card-title class="display-3 ml-3">{{ $t("menu.me") }}</va-card-title>
    </va-card>

    <va-card>
      <va-card-content>
        <div class="row ml-1 mt-2">
          <div class="flex xs12 lg6 xl4">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("user_view.email")
              }}</va-card-title>
              <va-card-content>
                <va-input v-model="user.email" type="email" readonly />
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs12 lg6 xl4">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("user_view.user_name")
              }}</va-card-title>
              <va-card-content>
                <div class="row flex">
                  <va-input
                    v-model="user.fullname"
                    :readonly="user.email === 'admin' ? true : false"
                    :success="user.email === 'admin' ? false : true"
                    :error="user.fullname === '' || user.fullname === null"
                    :error-messages="[$t('me_view.fullname_error')]"
                  >
                  </va-input>
                  <div
                    v-if="user.fullname !== user.origfullname"
                    class="text--center"
                  >
                    <va-button
                      color="danger"
                      size="small"
                      @click="clearNameChange"
                      class="mr-3"
                      >{{ $t("common_forms.cancel") }}</va-button
                    >
                    <va-button
                      color="info"
                      icon="fa-icon fa fa-pencil"
                      size="small"
                      @click="showEditUser = true"
                    >
                      {{ $t("common_forms.edit") }}
                    </va-button>
                  </div>
                </div>
              </va-card-content>
            </va-card>
          </div>
        </div>

        <div class="row ml-1">
          <div class="flex xs12 lg6 xl4">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("user_view.org_id")
              }}</va-card-title>
              <va-card-content>
                <va-input v-model="user.organizationId" readonly />
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs6 lg3 xl2">
            <div class="flex row">
              <va-card :color="enabledColor">
                <va-card-title class="display-4">{{
                  $t("user_view.active")
                }}</va-card-title>
              </va-card>
            </div>
            <div class="flex row">
              <va-button color="primary" @click="showEditPasswordF">{{
                $t("me_view.change_password")
              }}</va-button>
            </div>
          </div>

          <div class="flex xs6 lg3 xl2 text-center">
            <va-card :color="enabled2FAColor">
              <va-card-title class="display-4">{{
                $t("user_view.2FA")
              }}</va-card-title>
              <va-card-content>
                <va-button
                  v-if="!user.enabled2FA"
                  color="success"
                  @click="showActivate2FA"
                  >{{ $t("me_view.activate") }}</va-button
                >
                <va-button
                  v-if="user.enabled2FA"
                  color="danger"
                  @click="showClear2FA = true"
                  >{{ $t("me_view.deactivate") }}</va-button
                >
              </va-card-content>
            </va-card>
          </div>
        </div>

        <div class="row ml-1 mb-2">
          <div class="flex xs12 lg12 xl8">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("user_view.apps_access")
              }}</va-card-title>
              <va-card-content>
                <va-input v-model="user.appsAccessListString" readonly />
              </va-card-content>
            </va-card>
          </div>
        </div>

        <div class="row ml-1 mb-2">
          <div class="flex xs12 lg12 xl8">
            <va-card outlined>
              <va-card-title class="display-4">{{
                $t("user_view.access")
              }}</va-card-title>
              <va-card-content>
                <div class="row flex">
                  <va-select
                    v-model="user.accessListNames"
                    :label="$t('user_view.access')"
                    searchable
                    multiple
                    clearable
                    :readonly="user.email === 'admin' ? true : false"
                    :success="user.email === 'admin' ? false : true"
                    :options="accessNames"
                  />
                  <div v-if="accessListNamesChanged" class="text--center">
                    <va-button
                      color="danger"
                      size="small"
                      @click="clearAccessListChange"
                      class="mr-3"
                      >{{ $t("common_forms.cancel") }}</va-button
                    >
                    <va-button
                      color="info"
                      icon="fa-icon fa fa-pencil"
                      size="small"
                      @click="showEditAccessList = true"
                    >
                      {{ $t("common_forms.edit") }}
                    </va-button>
                  </div>
                </div>
              </va-card-content>
            </va-card>
          </div>
        </div>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="showEditAccessList"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('me_view.change_access') + ' \'' + user.accessListNames + '\' ?'
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("user_view.edit_user") }}
        </p>
      </template>
      <template #footer>
        <va-button
          color="danger"
          @click="
            showEditAccessList = false;
            clearAccessListChange();
          "
          class="mr-3"
          >{{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="info" @click="editAccess" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showEditUser"
      size="medium"
      :hideDefaultActions="true"
      :message="$t('me_view.change_name') + ' \'' + user.fullname + '\' ?'"
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("user_view.edit_user") }}
        </p>
      </template>
      <template #footer>
        <va-button
          color="danger"
          @click="
            showEditUser = false;
            clearNameChange();
          "
          class="mr-3"
          >{{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="info" @click="editUser" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showEditPassword"
      size="medium"
      :hideDefaultActions="true"
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("me_view.change_password") }}
        </p>
      </template>

      <slot>
        <div class="row mb-3 ml-3 mr-3">
          <va-input
            class="flex xs12 md12"
            v-model="editPassword.email"
            :label="$t('user_view.email')"
            type="text"
            readonly
          />
        </div>
        <div class="row mb-3 ml-3 mr-3 formRow">
          <va-input
            class="flex xs12 md12"
            v-model="editPassword.oldPassword"
            type="password"
            :label="$t('me_view.old_password')"
          />
        </div>
        <div class="row mb-3 ml-3 mr-3 formRow">
          <va-input
            class="flex xs12 md12"
            v-model="editPassword.password"
            type="password"
            :label="$t('me_view.new_password')"
            :error="!!errors.editPasswordErrors.newPasswordError.length"
            :error-messages="errors.editPasswordErrors.newPasswordError"
          />
        </div>
        <div class="row mb-3 ml-3 mr-3 formRow">
          <va-input
            class="flex xs12 md12"
            v-model="editPassword.passwordAgain"
            type="password"
            :label="$t('me_view.new_password_again')"
            :error="!!errors.editPasswordErrors.newPasswordError.length"
            :error-messages="errors.editPasswordErrors.newPasswordError"
          />
        </div>
      </slot>
      <template #footer>
        <va-button color="danger" @click="cancelEditPassword" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="info" @click="changePassword" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal v-model="showSet2FA" size="large" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("me_view.set_2FA") }}
        </p>
      </template>
      <slot>
        <p>{{ $t("me_view.activation_2FA_text") }}</p>
        <ol class="va-ordered">
          <li>{{ $t("me_view.activation_2FA_step1") }}</li>
          <li>{{ $t("me_view.activation_2FA_step2") }}</li>
          <li>{{ $t("me_view.activation_2FA_step3") }}</li>
          <li>{{ $t("me_view.activation_2FA_step4") }}</li>
        </ol>
        <p style="color: #ff6509">{{ $t("me_view.activation_2FA_warning") }}</p>
        <div class="row ma-3">
          <va-input
            class="flex xs12 md12"
            v-model="activation2FA.manualEntryKey"
            :label="$t('me_view.manual_2FA')"
            type="text"
            readonly
          />
        </div>
        <div class="row mb-3 ml-3 mr-3" style="justify-content: center">
          <img :src="activation2FA.qrCodeSetupImageUrl" />
        </div>
        <div class="row mb-3 ml-3 mr-3">
          <va-input
            class="flex xs12 md12"
            v-model="code2FA"
            :label="$t('me_view.code_2FA')"
            :error="!!errors.pin2FA.length"
            :error-messages="errors.pin2FA"
          />
        </div>
      </slot>
      <template #footer>
        <va-button color="danger" @click="cancelActive2FA" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="success" @click="activate2FA">{{
          $t("me_view.activate")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showClear2FA"
      size="medium"
      :hideDefaultActions="true"
      :message="$t('me_view.deactivation_2FA')"
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("me_view.set_2FA") }}
        </p>
      </template>
      <slot>
        <div class="row mb-3 ml-3 mr-3">
          <va-input
            v-model="code2FA"
            :label="$t('me_view.code_2FA')"
            :error="!!errors.pin2FA.length"
            :error-messages="errors.pin2FA"
          />
        </div>
      </slot>
      <template #footer>
        <va-button color="info" @click="cancelDeactive2FA" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="danger" @click="deactivate2FA">{{
          $t("me_view.deactivate")
        }}</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {
        email: "",
        fullname: "",
        origfullname: "",
        organizationId: "",
        enabled2FA: false,
        enabled: false,
        active: "",
        appsAccessList: [],
        appsAccessListNames: [],
        appsAccessListString: "",
        accessListNames: [],
        accessListNamesOrig: [],
        accessList: [],
      },
      accesses: [],
      accessNames: [],
      showEditUser: false,
      showEditAccessList: false,
      showEditPassword: false,
      showSet2FA: false,
      showClear2FA: false,
      editPassword: {
        email: "",
        oldPassword: "",
        password: "",
        passwordAgain: "",
      },
      activation2FA: {
        account: "",
        manualEntryKey: "",
        qrCodeSetupImageUrl: "",
      },
      code2FA: "",
      errors: {
        editPasswordErrors: {
          newPasswordError: [],
        },
        pin2FA: [],
      },
    };
  },

  created() {
    this.getUser();
    this.getAccs();
  },

  computed: {
    ...mapGetters(["loginType"]),
    enabledColor() {
      return this.user.enabled === true ? "success" : "danger";
    },
    enabled2FAColor() {
      return this.user.enabled2FA === true ? "success" : "danger";
    },
    accessListNamesChanged() {
      return (
        JSON.stringify(this.user.accessListNames.sort()) !==
        JSON.stringify(this.user.accessListNamesOrig.sort())
      );
    },
  },

  methods: {
    getUser() {
      this.backend.getUser().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.user = data;
          this.user.origfullname = this.user.fullname;
          this.user.appsAccessListNames = [];
          for (let j = 0; j < this.user.appsAccessList.length; j++) {
            this.user.appsAccessListNames.push(
              this.user.appsAccessList[j].name
            );
            this.user.appsAccessListString = JSON.stringify(
              this.user.appsAccessListNames
            );
          }
          this.user.accessListNames = [];
          for (let k = 0, len = this.user.accessList.length; k < len; k++) {
            this.user.accessListNames.push(this.user.accessList[k].name);
          }
          this.user.accessListNames = [...new Set(this.user.accessListNames)];
          this.user.accessListNamesOrig = this.user.accessListNames;
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },
    getAccs() {
      this.backend.listAcc().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.accesses = data;
          this.accessNames = [];
          for (let i = 0, len = this.accesses.length; i < len; i++) {
            this.accessNames.push(this.accesses[i].name);
          }
          // console.log(JSON.stringify(this.accessNames))
          this.accessNames = [...new Set(this.accessNames)];
          // console.log(JSON.stringify(this.accessNames))
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    clearNameChange() {
      this.user.fullname = this.user.origfullname;
    },
    clearAccessListChange() {
      this.user.accessListNames = this.user.accessListNamesOrig;
    },

    editUser() {
      if (this.user.fullname === "" || this.user.fullname === null) {
        return;
      }
      this.backend.changeMyName(this.user.fullname).then((d2) => {
        if (d2 !== false) {
          this.user.origfullname = this.user.fullname;
          this.showEditUser = false;
        } else {
          this.launchToast(this.$t("user_view.error_edit"), "warning");
        }
      });
    },
    editAccess() {
      let newAccessList = [];
      for (let i = 0, len = this.user.accessListNames.length; i < len; i++) {
        newAccessList.push(
          ...this.accesses.filter(
            (a) => a.name === this.user.accessListNames[i]
          )
        );
      }
      // filer access to have unique values
      newAccessList = [...new Set(newAccessList)];
      // console.log(JSON.stringify(newAccessList))
      this.backend.changeMyAccess(newAccessList).then((d2) => {
        if (d2 !== false) {
          this.user.accessListNamesOrig = this.user.accessListNames;
          this.user.accessList = newAccessList;
          this.showEditAccessList = false;
        } else {
          this.launchToast(this.$t("user_view.error_edit"), "warning");
        }
      });
    },
    showActivate2FA() {
      if (this.activation2FA.account !== "") {
        this.showSet2FA = true;
        return;
      }
      this.backend.new2FA().then((d2) => {
        if (d2 !== false) {
          this.activation2FA = d2;
          this.errors.errors = [];
          this.showSet2FA = true;
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },
    activate2FA() {
      if (this.code2FA === "") {
        this.errors.pin2FA = [this.$t("auth.error_code_2FA")];
        return;
      }
      this.backend.set2FA(this.code2FA).then((d2) => {
        if (d2.status !== false) {
          this.showSet2FA = false;
          this.code2FA = "";
          this.activation2FA.account = "";
          this.activation2FA.manualEntryKey = "";
          this.activation2FA.qrCodeSetupImageUrl = "";
          this.errors.pin2FA = [];
          this.user.enabled2FA = true;
        } else {
          if (d2.data === "invalidPIN") {
            this.errors.pin2FA = [this.$t("auth.error_code_2FA")];
          } else {
            this.launchToast(this.$t("tables_common.error_data"), "warning");
          }
        }
      });
    },
    cancelActive2FA() {
      this.showSet2FA = false;
      this.code2FA = "";
      this.activation2FA.account = "";
      this.activation2FA.manualEntryKey = "";
      this.activation2FA.qrCodeSetupImageUrl = "";
      this.errors.pin2FA = [];
    },
    deactivate2FA() {
      if (this.code2FA === "") {
        this.errors.pin2FA = [this.$t("auth.error_code_2FA")];
        return;
      }
      this.backend.clear2FA(this.code2FA).then((d2) => {
        if (d2.status !== false) {
          this.showClear2FA = false;
          this.code2FA = "";
          this.errors.pin2FA = [];
          this.user.enabled2FA = false;
        } else {
          if (d2.data === "invalidPIN") {
            this.errors.pin2FA = [this.$t("auth.error_code_2FA")];
          } else {
            this.launchToast(this.$t("tables_common.error_data"), "warning");
          }
        }
      });
    },
    cancelDeactive2FA() {
      this.showClear2FA = false;
      this.code2FA = "";
      this.errors.pin2FA = [];
    },

    showEditPasswordF() {
      this.editPassword.email = this.user.email;
      this.showEditPassword = true;
    },
    cancelEditPassword() {
      this.showEditPassword = false;
      this.editPassword.email = "";
      this.editPassword.oldPassword = "";
      this.editPassword.password = "";
      this.editPassword.passwordAgain = "";
      this.errors.editPasswordErrors.newPasswordError = [];
    },
    changePassword() {
      this.errors.editPasswordErrors.newPasswordError =
        this.editPassword.password && this.editPassword.passwordAgain
          ? []
          : [this.$t("auth.password_not_present")];
      if (
        this.editPassword.password &&
        this.editPassword.passwordAgain &&
        this.editPassword.password !== this.editPassword.passwordAgain
      ) {
        this.errors.editPasswordErrors.newPasswordError = [
          this.$t("auth.passwords_not_match"),
        ];
      }
      if (this.errors.editPasswordErrors.newPasswordError.length) {
        return;
      }
      this.backend.changeMyPassword(this.editPassword).then((d2) => {
        if (d2 !== false) {
          this.cancelEditPassword();
        } else {
          this.launchToast(this.$t("user_view.error_edit"), "warning");
        }
      });
    },

    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>
<style lang="scss">
.formRow {
  min-width: 20vw;
}
</style>
